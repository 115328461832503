import { get } from 'lodash';
import { generateDeepLink } from 'site-modules/shared/utils/car-buying-online/generate-deep-link';
import { REVIEWS_SERVICE } from 'client/constants/dealer-reviews';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import {
  isCarMax,
  isDriveway,
  isCarvana,
  isPartnerDealer,
  isJakeSweeneyExpress,
  isAutonationMobility,
  isRubycar,
} from 'site-modules/shared/utils/dealer-details-utils';
import { DRIVEWAY_DCM_CLICK_TAG } from 'site-modules/shared/constants/inventory/dcm-click-tag';
import { isUsed } from 'site-modules/shared/utils/inventory-utils/is-used';
import { makeDDPCityStateName } from './nice-name';
import { addHttp } from './add-http';

export const UTM_SOURCE_PARAMS = 'utm_source=edmunds';
export const UTM_MEDIUM_PARAMS = 'utm_medium=referral';
export const REFERRAL_SUFFIX = `${UTM_SOURCE_PARAMS}&${UTM_MEDIUM_PARAMS}`;
const PARAMS_ORDER = ['adcode', 'utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'edm_zip'];

export function getDealershipStateCityNameUrl({ state, city, dealership, params = {} }) {
  const queryParams = objectToQueryString(params);

  return `/dealerships/all/${makeDDPCityStateName(state)}/${makeDDPCityStateName(city)}/${dealership}/${
    queryParams.length ? '?' : ''
  }${queryParams}`;
}

export function getDealershipWriteReviewLink({ state, city, dealership, reviewType, showroomPage }) {
  const dealershipPageLink = showroomPage ? showroomPage.replace('/all/', '/') : '';
  switch (reviewType) {
    case REVIEWS_SERVICE:
      return dealershipPageLink
        ? `${dealershipPageLink}/create-service-review.html`
        : `/dealerships/${makeDDPCityStateName(state)}/${makeDDPCityStateName(
            city
          )}/${dealership}/create-service-review.html`;
    default:
      return dealershipPageLink
        ? `${dealershipPageLink}/create-sales-review.html`
        : `/dealerships/${makeDDPCityStateName(state)}/${makeDDPCityStateName(
            city
          )}/${dealership}/create-sales-review.html`;
  }
}

/**
 * Returns dealer website link or Google link with search query.
 * If dealer website link is presented, checks edmunds referral params existing.
 * Adds edmunds referral if it is not presented in the dealer website url.
 * @param {String[]} [dealerWebSites]
 * @param {String} dealerName
 * @param {String} city
 * @param {String} stateCode
 * @returns {String}
 */
export function getDealerWebsiteExternalLink({ dealerWebSites, dealerName, city, stateCode }) {
  const website = get(dealerWebSites, '[0]', null);

  if (website) {
    const http = addHttp(website);
    const lowerCasedHttp = http.toLowerCase();

    if (lowerCasedHttp.includes('?')) {
      return lowerCasedHttp.includes('utm_source=edmunds') ? http : `${http}&${REFERRAL_SUFFIX}`;
    }
    return `${http}?${REFERRAL_SUFFIX}`;
  }

  return `//www.google.com/search?btnI&q=${encodeURIComponent(`${dealerName} ${city} ${stateCode}`)}`;
}

/**
 * Extracts value from dealerWebSites.
 * Handles empty value ( null, undefined, {} ) that might be passed.
 * @param {Object} dealerInfo
 * @returns {boolean|*|string}
 */
export function getDealerWebsitesValue(dealerInfo) {
  return get(dealerInfo, 'dealerWebSites[0]', '');
}

/**
 * Returns index of query delimiter or string length if delimiter is not found
 * @param {String}  str
 * @param {String} delimiter
 * @returns {Number}
 */
export function getSubStringIndex(str, delimiter = '?') {
  const index = str.indexOf(delimiter);
  return index > 0 ? index : str.length;
}

/**
 * Gets dealer web site url value to display for user
 * @param {string} deepLinkUrl
 * @returns {string}
 */
export function getDealerWebsiteUrl(deepLinkUrl) {
  const toIndex = getSubStringIndex(deepLinkUrl);
  /* Cutting off tracking params from url with purpose to hide it from user */
  return deepLinkUrl.substring(0, toIndex);
}

/**
 * Checks if trackingParams contain utm_source and utm_medium and adds it if not
 * @param {string} trackingParams
 * @returns {string}
 */
export function checkUTMParams(trackingParams) {
  const trackingParamsLowerCase = trackingParams.toLowerCase();
  let params = trackingParams;

  if (!trackingParamsLowerCase.includes('utm_source=')) {
    params = `${params}&${UTM_SOURCE_PARAMS}`;
  }
  if (!trackingParamsLowerCase.includes('utm_medium=')) {
    params = `${params}&${UTM_MEDIUM_PARAMS}`;
  }
  return params;
}

/**
 * Gets tracking params for dealer web sites url
 * @param {string} deepLinkUrl
 * @returns {string}
 */
export function getDealerWebsiteTrackingParams(deepLinkUrl) {
  const fromIndex = getSubStringIndex(deepLinkUrl);
  const trackingParams = deepLinkUrl.substring(fromIndex, deepLinkUrl.length);

  return trackingParams ? checkUTMParams(trackingParams) : `?${REFERRAL_SUFFIX}`;
}

/**
 * Updates tracking params for dealer web sites url
 * @param {string} deepLinkUrl
 * @param {string} dealer
 * @param {string} zipCode
 * @param {boolean} isExtended
 * @param {boolean} isUsedType
 * @returns {string}
 */

export function updateDealerWebsiteTrackingParams({
  deepLinkUrl,
  dealer,
  zipCode,
  isExtended,
  isDealerInfo,
  isUsedType,
  type = '',
}) {
  const trackingParams = new URLSearchParams(deepLinkUrl.split('?')[1]);
  if (isCarMax(dealer)) {
    const adcode = trackingParams.get('adcode') || 'EDM';
    const adCodeSuffixs = isUsedType ? '_2' : '';

    if (!isDealerInfo) {
      if (isExtended) {
        trackingParams.set('adcode', adcode.replace('EDM', 'EDMonlsx').concat('', adCodeSuffixs));
      } else {
        trackingParams.set('adcode', adcode.replace('EDM', 'EDMonls').concat('', adCodeSuffixs));
      }

      trackingParams.set('utm_campaign', 'LG_OC_ONLS');
      trackingParams.set('utm_source', 'LG_OC_EDMonls');
    }
    trackingParams.set('edm_zip', zipCode);
  } else {
    const utmSource = trackingParams.get('utm_source') || 'edmunds';
    const utmMedium = trackingParams.get('utm_medium') || 'referral';

    trackingParams.set('utm_source', utmSource);
    trackingParams.set('utm_medium', utmMedium);
    if ((isDriveway(dealer) || isRubycar(dealer)) && !isDealerInfo) {
      const utmCampaignValue = `vdp${isExtended ? 'x' : ''}`;
      trackingParams.set('utm_campaign', utmCampaignValue);
    } else if (isDriveway(dealer) && isDealerInfo) {
      trackingParams.set('utm_campaign', type.toLowerCase());
    } else if (isJakeSweeneyExpress(dealer)) {
      trackingParams.set('utm_campaign', 'bol_vdp');
    } else {
      trackingParams.set('utm_term', 'vdpdeeplink');
    }
  }

  return `?${trackingParams.toString()}`;
}

/**
 * Fixes utm_medium tracking param for dealer website urls on Buy Online pages
 * @param {string} deepLinkUrl
 * @param {string} dealer
 * @param {boolean} isSRP
 * @returns {string}
 */
export function fixUtmMediumParam(deepLink, dealer, isSRP) {
  if ((!isSRP && !isCarMax(dealer)) || !deepLink) {
    return deepLink;
  }

  if (!isCarMax(dealer)) {
    return deepLink;
  }

  const dealerWebsiteUrl = getDealerWebsiteUrl(deepLink);
  const trackingParams = new URLSearchParams(deepLink.split('?')[1]);

  if (isCarMax(dealer)) {
    trackingParams.set('utm_medium', 'OC');
  }

  return `${dealerWebsiteUrl}?${trackingParams}`;
}

export function addAdditionalUTMParams({
  deepLink,
  dealer,
  isSRP = false,
  isExtended = false,
  isBuyOnline = false,
  isUsedType = true,
  additionalParams = '',
}) {
  if (!deepLink) return '';

  const isDrivewayDealer = isDriveway(dealer);
  const type = isUsedType ? 'used' : 'new';

  const deepLinkUtm = fixUtmMediumParam(deepLink, dealer, isSRP);
  const delimeter = deepLinkUtm.includes('?') ? '&' : '?';

  const utmContent = isSRP ? 'SRPclickout' : 'carpage';
  const utmTerm = isSRP ? 'vdpdeeplink_srp' : 'vdpdeeplink';
  const vdpUtmCampaign = `vdp${isExtended ? 'x' : ''}${isDrivewayDealer ? `_${type}` : ''}`;
  const utmCampaign = isSRP ? `srp${isDrivewayDealer ? `_${type}` : ''}` : vdpUtmCampaign;
  let utmParamValue = utmTerm;
  let param = 'utm_term';

  if (isCarMax(dealer)) {
    param = 'utm_content';
    utmParamValue = utmContent;
  }
  if (isDriveway(dealer) || isRubycar(dealer)) {
    param = 'utm_campaign';
    utmParamValue = utmCampaign;
  }

  const hasUtmContent = deepLinkUtm.includes(param);
  const dealerWebsiteUrl = getDealerWebsiteUrl(deepLinkUtm);
  const trackingParams = new URLSearchParams(deepLinkUtm.split('?')[1]);
  if (hasUtmContent) {
    trackingParams.set(param, utmParamValue);

    if (isCarMax(dealer) && isSRP) {
      const sortedParams = new URLSearchParams('');
      PARAMS_ORDER.forEach(parameter => sortedParams.append(parameter, trackingParams.get(parameter)));

      return `${dealerWebsiteUrl}?${sortedParams}${additionalParams}`;
    }

    return `${dealerWebsiteUrl}?${trackingParams}${additionalParams}`;
  }

  if (isCarMax(dealer) && isSRP) {
    const sortedParams = new URLSearchParams('');

    PARAMS_ORDER.forEach(parameter =>
      parameter === param
        ? sortedParams.append(parameter, utmParamValue)
        : sortedParams.append(parameter, trackingParams.get(parameter))
    );

    return `${dealerWebsiteUrl}?${sortedParams}${additionalParams}`;
  }
  if (isJakeSweeneyExpress(dealer)) {
    if (isSRP || !isBuyOnline) {
      trackingParams.delete('utm_campaign', 'bol_vdp');
    }
    if (isSRP) {
      trackingParams.set('utm_campaign', 'bol_srp');
    }

    return `${dealerWebsiteUrl}?${trackingParams}`;
  }

  return `${deepLinkUtm}${delimeter}${param}=${utmParamValue}${additionalParams}`;
}

export function getDealerDeepLinkUrl({ deepLink, isCarmaxCheckoutCartEnabled = false, isProdEnv = false }) {
  if (isCarmaxCheckoutCartEnabled) {
    return `https://${isProdEnv ? 'www' : 'wwwqa'}.carmax.com/activity/shopping/third-party`;
  }
  return getDealerWebsiteUrl(deepLink);
}

export function getCommonDeepLink({ isExtended = false, vinData, dealershipInfo }) {
  const vinDataDealerInfo = get(vinData, 'dealerInfo');

  return isExtended
    ? {
        deepLinkUrl: getDealerWebsitesValue(dealershipInfo),
        deepLink: generateDeepLink(vinData) || getDealerWebsitesValue(dealershipInfo),
      }
    : {
        deepLinkUrl: getDealerWebsitesValue(vinDataDealerInfo),
        deepLink: generateDeepLink(vinData) || getDealerWebsitesValue(vinDataDealerInfo),
      };
}
export function getBuyOnlineDeepLink({ vinData, dealershipInfo, dealer }) {
  const isDrivewayDealer = isDriveway(dealer);
  const isCarmaxDealer = isCarMax(dealer);
  const isCarvanaDealer = isCarvana(dealer);
  const vinDataDealerInfo = get(vinData, 'dealerInfo');

  if (isCarvanaDealer) {
    const deepLink = get(vinData, 'deepLinkUrl', getDealerWebsitesValue(dealershipInfo));

    return { deepLink, deepLinkUrl: getDealerWebsiteTrackingParams(deepLink) };
  }

  if (isDrivewayDealer || isCarmaxDealer) {
    return {
      deepLinkUrl: getDealerWebsitesValue(dealershipInfo),
      deepLink: generateDeepLink(vinData) || getDealerWebsitesValue(dealershipInfo),
    };
  }

  return {
    deepLinkUrl: getDealerWebsitesValue(vinDataDealerInfo),
    deepLink: generateDeepLink(vinData) || getDealerWebsitesValue(vinDataDealerInfo),
  };
}

export const getDealerLinkWithUTMParams = ({
  vinData,
  dealershipInfo,
  zipCode,
  dealer,
  isProdEnv,
  isExtended = false,
  isBuyOnline = false,
  getDeepLink,
}) => {
  const isCarmaxDealer = isCarMax(dealer);
  const isDrivewayDealer = isDriveway(dealer);
  const isAutonationMobilityEnabled = isAutonationMobility(vinData);
  const isPartner = isPartnerDealer(vinData);
  const isUsedType = isUsed(get(vinData, 'type', ''));
  const stockNumber = get(vinData, 'stockNumber', '');

  const { deepLink, deepLinkUrl } = getDeepLink({ vinData, dealershipInfo, dealer, isExtended });

  if (isAutonationMobilityEnabled) return deepLink;

  const isCarmaxCheckoutCartEnabled = isUsedType && isCarmaxDealer;
  const dealerWebSiteUrl = getDealerDeepLinkUrl({ deepLink, isCarmaxCheckoutCartEnabled, isProdEnv });

  const url = `${dealerWebSiteUrl}${updateDealerWebsiteTrackingParams({
    deepLinkUrl,
    dealer,
    zipCode,
    isExtended,
    isUsedType,
  })}`;

  const dcmClickTag = isDrivewayDealer && isPartner ? DRIVEWAY_DCM_CLICK_TAG : '';

  return `${dcmClickTag}${addAdditionalUTMParams({
    deepLink: url,
    dealer,
    isSRP: false,
    isExtended,
    isBuyOnline,
    isUsedType,
    ...(isCarmaxCheckoutCartEnabled && {
      additionalParams: `&stocknumber=${stockNumber}&hvar=2`,
    }),
  })}`;
};
