import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  SRPCreativeConfigPropTypes,
  SRPCreativeConfigDefaultProps,
} from 'client/data/models/native-ads-creative-config';
import { AdUnitPropTypes, AdUnitDefaultProps } from 'client/site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { isEmptyCreativeConfig } from 'client/site-modules/shared/components/native-ad/utils/utils';
import { SRP_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { withSiteServedAdWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';

/*
  Wrapper component for a SRP page that hide cards with empty ad units in the vehicle listing.
*/

export function SRPSiteServedRetentionUI(props) {
  const {
    creativeConfig,
    position,
    ivtClassName,
    wrapperTag: WrapperTag,
    wrapperTagClassName,
    wrapperAdComponentClassName,
    siteServedComponent: SiteServedAdComponent,
    ...restProps
  } = props;

  if (isEmptyCreativeConfig(creativeConfig)) {
    return null;
  }

  const nativeStyle = SRP_AD.NATIVE_STYLE;

  return (
    <WrapperTag
      className={classnames(wrapperTagClassName, ivtClassName)}
      data-tracking-parent={nativeStyle}
      data-viewability={`${nativeStyle}-${position}`}
    >
      <div className={wrapperAdComponentClassName}>
        <SiteServedAdComponent position={position} {...restProps} creativeConfig={creativeConfig} />
      </div>
    </WrapperTag>
  );
}

SRPSiteServedRetentionUI.propTypes = {
  siteServedComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  mobile: PropTypes.bool,
  creativeConfig: SRPCreativeConfigPropTypes,
  currentVehicle: AdUnitPropTypes.currentVehicle,
  dependedOnCurrentVehicle: PropTypes.bool,
  customTargeting: AdUnitPropTypes.customTargeting,
  position: PropTypes.string,
  offersNumber: PropTypes.number,
  ivtClassName: PropTypes.string,
  wrapperTag: PropTypes.string,
  wrapperTagClassName: PropTypes.string,
  wrapperAdComponentClassName: PropTypes.string,
};

SRPSiteServedRetentionUI.defaultProps = {
  mobile: false,
  creativeConfig: SRPCreativeConfigDefaultProps,
  currentVehicle: AdUnitDefaultProps.currentVehicle,
  dependedOnCurrentVehicle: false,
  customTargeting: AdUnitDefaultProps.customTargeting,
  position: '0',
  offersNumber: 0,
  ivtClassName: null,
  wrapperTag: 'div',
  wrapperTagClassName: null,
  wrapperAdComponentClassName: null,
};

export const SRPSiteServedRetention = withSiteServedAdWrapper(SRPSiteServedRetentionUI);
