import { get } from 'lodash';
import {
  DEALERS_WEB_SITES,
  CARVANA,
  CARMAX,
  SHIFT,
  JAKE_SWEENEY_EXPRESS,
  RUBYCAR,
} from 'site-modules/shared/constants/inventory/buy-online-dealers';

// TODO: This functionality should be move into displayvin API with TED-4962
export const generateDeepLink = vinData => {
  const dealer = get(vinData, 'dealerInfo.displayInfo.parentDealershipName');
  const deepLink =
    dealer === SHIFT || dealer === RUBYCAR
      ? get(vinData, 'listingUrl', get(vinData, 'deepLinkUrl'))
      : get(vinData, 'deepLinkUrl', get(vinData, 'listingUrl'));

  if (deepLink) {
    const query = deepLink.substring(deepLink.indexOf('?') + 1);
    const searchParams = new URLSearchParams(query);
    const vehicleId = searchParams.get('utm_vehicle_id');

    if (dealer === CARVANA && vehicleId) {
      return `${DEALERS_WEB_SITES[dealer].webSite}/vehicle/${vehicleId}?${query}`;
    }
    return deepLink;
  }

  if (dealer === CARMAX) {
    const stockNumber = get(vinData, 'stockNumber');
    return `${DEALERS_WEB_SITES[dealer].webSite}/car/${stockNumber}`;
  }

  if (dealer === SHIFT) {
    return DEALERS_WEB_SITES[dealer].webSite;
  }
  if (dealer === JAKE_SWEENEY_EXPRESS) {
    return `${DEALERS_WEB_SITES[dealer].webSite}/details/${get(vinData, 'vin')}`;
  }

  return null;
};
