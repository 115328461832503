import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { CommonMap } from 'client/tracking/maps/common';
import { SEE_INVENTORY_BY_PRICE } from 'site-modules/calculators/constants/tracking';

const map = {
  load_loan_calculator: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.SHOW_LOAN_PAYMENTS,
    {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    }
  ),
  load_lease_calculator: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.SHOW_LEASE_PAYMENTS,
    {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
    }
  ),
  click_edit_loan_payment: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.EDIT_LOAN_PAYMENT
  ),
  click_edit_lease_payment: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.EDIT_LEASE_PAYMENT
  ),
  click_close_loan_calculator: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.CLOSE_LOAN_CALCULATOR
  ),
  click_close_lease_calculator: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.CLOSE_LEASE_CALCULATOR
  ),
  select_loan_payment_term: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SELECT_LOAN_PAYMENT_TERM
  ),
  select_lease_payment_term: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SELECT_LEASE_PAYMENT_TERM
  ),
  click_vehicle_price: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.EDIT_VEHICLE_PRICE
  ),
  select_mileage: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SELECT_ANNUAL_MILES
  ),
  select_loan_down_payment: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_LOAN_DOWN_PAYMENT
  ),
  select_lease_down_payment: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_LEASE_DOWN_PAYMENT
  ),
  select_trade_in_value: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_TRADE_IN_VALUE
  ),
  select_apr: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_ANNUAL_PERCENTAGE_RATE
  ),
  select_appraise: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, TrackingConstant.APPRAISE_MY_CAR),
  input_credit_score: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_CREDIT_SCORE
  ),
  taxes_and_fees_zip_input: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.INPUT_ZIP_CODE
  ),
  prepopulate_trade_in_value: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHOW_CONTENT,
    TrackingConstant.PREPOPULATE_TRADE_IN
  ),
  share_deal_payments: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_SHARE_CONTENT,
    TrackingConstant.SHARE_DEAL
  ),
  open_share_deal: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.SHARE_CALCULATOR
  ),
  copy_link: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHARE_CONTENT, TrackingConstant.COPY_LINK),
  copy_summary: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHARE_CONTENT, TrackingConstant.COPY_LINK),
  input_custom_price: TrackingEvent.actionCompleted(TrackingConstant.ACTION_TOOL_SELECT, TrackingConstant.INPUT_PRICE),
  select_incentives: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_PURCHASE_VEHICLE,
    TrackingConstant.SELECT_INCENTIVES
  ),
  view_suggested_price_details_drawer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.VIEW_PRICE_DETAILS
  ),
  financing_cta: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_FILL_FORM,
    TrackingConstant.SUBACTION_OPEN_FINANCING_APP,
    {
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
    }
  ),
  see_inventory_by_price: TrackingEvent.actionCompleted(TrackingConstant.VIEW_SEARCH_RESULT, SEE_INVENTORY_BY_PRICE),
  car_equity_sell_my_car: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SELL_MY_CAR
  ),
  car_equity_customize_payments: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.CUSTOMIZE_PAYMENTS
  ),
  car_equity_get_prequalified: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.GET_PREQUALIFIED
  ),
};

export const CalculatorMap = {
  ...CommonMap,
  ...map,
};
