/**
 * the name of secondary CPO program must be "make-secondary" and have corresponding description as value in metadatas
 * in edmunds/content/certified-cars/certified-program-compare-new.atom
 * for example: <ed:metadata name="honda-secondary">HondaTrue Certified+</ed:metadata>
 */
export const SECONDARY_PROGRAM_POSTFIX = '-secondary';
/**
 * the ID of the entry with secondary program
 * in the atom file like edmunds/content/honda/certified-program-.atom
 */
export const SECONDARY_PROGRAM_ID = 'secondary-program';
