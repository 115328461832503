import { SECONDARY_PROGRAM_ID } from 'site-modules/shared/constants/cpo-constants';

export function getCpoMakeContentPath(make) {
  return `/${make}/certified-program`;
}

const buildCpoProgram = programEntity => ({
  title: programEntity.metadata('cpoName')?.value(),
  content: programEntity.metadata('content')?.value(),
});

/**
 * Returns cpo programs from certifiedPreOwnedCmsEntities
 *
 * @param {CmsEntities} certifiedPreOwnedCmsEntities
 * @return {Array} programs.
 */
export function getCpoPrograms(certifiedPreOwnedCmsEntities) {
  const firstCpoContent = certifiedPreOwnedCmsEntities?.metadata('content')?.value();

  if (!firstCpoContent) {
    return [];
  }

  const hasSecondaryCpoProgram = certifiedPreOwnedCmsEntities.hasChild(SECONDARY_PROGRAM_ID);
  if (hasSecondaryCpoProgram) {
    const secondaryProgram = certifiedPreOwnedCmsEntities.child(SECONDARY_PROGRAM_ID);

    return [buildCpoProgram(certifiedPreOwnedCmsEntities), buildCpoProgram(secondaryProgram)];
  }

  return [buildCpoProgram(certifiedPreOwnedCmsEntities)];
}

/**
 * Returns cpo content from certifiedPreOwnedCmsEntities
 *
 * @param {CmsEntities} certifiedPreOwnedCmsEntities
 * @return {Array} certifiedPreOwnedContent.
 */
export function getCpoContent(certifiedPreOwnedCmsEntities) {
  const programs = getCpoPrograms(certifiedPreOwnedCmsEntities).map(program => program.content);

  return programs.length ? programs : null;
}
