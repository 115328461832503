import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';

export class ContentSection extends Component {
  state = {
    isExpanded: !this.props.collapseOnLoad,
  };

  onExpandClick = () => {
    this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
  };

  render() {
    const {
      sectionTitle,
      children,
      className,
      line,
      dataTrackingParent,
      lineClassName,
      titleClassName,
      headerTag: Tag,
      collapseOnLoad,
    } = this.props;
    const { isExpanded } = this.state;

    return (
      <section className={classNames(className, 'text-gray-darker')} data-tracking-parent={dataTrackingParent}>
        {sectionTitle && !collapseOnLoad && <Tag className={classNames(titleClassName)}>{sectionTitle}</Tag>}
        {sectionTitle && collapseOnLoad && (
          <Button
            color="link"
            className={classNames('text-transform-none text-decoration-none p-0', titleClassName)}
            onClick={this.onExpandClick}
            data-tracking-id="view_more_info"
            data-tracking-value={isExpanded ? 'collapse' : 'expand'}
          >
            {sectionTitle}
            {collapseOnLoad && (
              <i
                className={classNames('text-primary-darker small ml-0_25', {
                  'icon-arrow-down4': !isExpanded,
                  'icon-arrow-up4': isExpanded,
                })}
                aria-hidden
              />
            )}
          </Button>
        )}
        {(!collapseOnLoad || (collapseOnLoad && isExpanded)) && children}
        {line && <hr className={classNames(lineClassName)} />}
      </section>
    );
  }
}

ContentSection.propTypes = {
  sectionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  className: PropTypes.string,
  line: PropTypes.bool,
  dataTrackingParent: PropTypes.string,
  lineClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  headerTag: PropTypes.string,
  collapseOnLoad: PropTypes.bool,
};

ContentSection.defaultProps = {
  sectionTitle: null,
  children: null,
  className: '',
  line: true,
  dataTrackingParent: '',
  lineClassName: 'mt-1_5 mb-2',
  titleClassName: 'section-title heading-4 mb-1_5',
  headerTag: 'h3',
  collapseOnLoad: false,
};
