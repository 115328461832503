import React, { PureComponent } from 'react';
import { isEmpty } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EventToolbox } from 'client/utils/event-toolbox';
import { ACTIONS } from 'client/engagement-handlers/inventory-engagement-handler/constants';
import { VisitorModel } from 'client/data/models/visitor';
import { PageModel } from 'client/data/models/page';

import { EngagementHandler } from 'client/engagement-handlers/index';
import { InventoryEngagementHandler } from 'client/engagement-handlers/inventory-engagement-handler/inventory-engagement-handler';

EngagementHandler.register(InventoryEngagementHandler);

/**
 * HOC for firing list tracking, when page if fully loaded or when zip code is changed and new inventory is loaded.
 * @param {function} WrappedComponent
 * @returns {InventoryListTracking}
 */
export function inventoryListTracking(WrappedComponent) {
  class InventoryListTracking extends PureComponent {
    componentDidUpdate(prevProps) {
      const { isPageLoaded, zipCode, inventoryData, listTrackingData, isPreloadSuccess } = this.props;
      const { isPageLoaded: prevIsPageLoaded, zipCode: prevZipCode, inventoryData: prevInventoryData } = prevProps;

      // look at the fact that the page is fully loaded (basically, preloader ended its work)
      const isPageFullyLoaded = isPageLoaded && isPageLoaded !== prevIsPageLoaded && isPreloadSuccess;

      // remember if zipcode is changed
      const zipCodeChanged = !!zipCode && zipCode !== prevZipCode;

      // remember if new inventory is loaded
      const newInventoryLoaded = isEmpty(prevInventoryData) && !isEmpty(inventoryData);

      // fire list tracking event only when page is fully loaded (preloader ended work) or
      // when zip code is changed and new inventory data is loaded
      if (isPageFullyLoaded || (zipCodeChanged && newInventoryLoaded)) {
        EventToolbox.fireCustomEvent(ACTIONS.INVENTORY_LIST_VIEW, {
          ...listTrackingData,
          useDealerPrice: true,
        });
      }
    }

    render() {
      const { isPageLoaded, listTrackingData, isPreloadSuccess, ...moreProps } = this.props;
      return <WrappedComponent {...moreProps} />;
    }
  }
  InventoryListTracking.displayName = `WithInventoryListTracking(${WrappedComponent.displayName ||
    WrappedComponent.name})`;
  return InventoryListTracking;
}

const stateToPropsConfig = {
  isPageLoaded: bindToPath('loading.isLoaded', PageModel),
  isPreloadSuccess: bindToPath('loading.isPreloadSuccess', PageModel),
  zipCode: bindToPath('location.zipCode', VisitorModel),
};

export const withInventoryListTracking = WrappedComponent =>
  connectToModel(inventoryListTracking(WrappedComponent), stateToPropsConfig);
