/**
 * Adds 'http://' before link string if link does not contain http(s)://
 * @param link
 * @returns {*}
 */
export function addHttp(link) {
  const isHttpLink = /^https?:\/\//.test(link);
  if (isHttpLink) {
    return link;
  }
  return `http://${link}`;
}

export const absoluteUrlRegExp = /^https?:\/\/|^\/\//i;
